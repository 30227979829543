import React from 'react';

// import slide1 from './slide1.jpg';
// import slide2 from './slide2.jpg';
// import slide3 from './slide3.jpg';
// import slide4 from './slide4.jpg';
// import slide5 from './slide5.jpg';

const SlideItem = (props) => {
	return (
		<React.Fragment>
			<div
				className='slide bw'
				style={{
					backgroundImage: `url(${props.url})`,
				}}>
				<h3 className={props.lng}>{props.text}</h3>
			</div>
		</React.Fragment>
	);
};

const slide1 =
	'https://images.unsplash.com/photo-1596166813075-c76653690552?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1819&q=80';
const slide2 =
	'https://images.unsplash.com/photo-1544230356-6e6f3ea39960?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
const slide3 =
	'https://images.unsplash.com/flagged/photo-1565187625775-d16a157b9234?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
const slide4 =
	'https://images.unsplash.com/photo-1578060124065-41f863eb9ebe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
const slide5 =
	'https://thumb.tildacdn.com/tild6433-6630-4537-a431-343262663665/-/format/webp/IMG_4528.JPG';

class LinesSlider extends React.Component {
	state = {
		images: [
			{ url: slide1, lng: 'lng-name_01', text: 'Серфiнг' },
			{ url: slide2, lng: 'lng-name_02', text: 'Вiндсерфiнг' },
			{ url: slide3, lng: 'lng-name_03', text: 'Вейксерфiнг' },
			{ url: slide4, lng: 'lng-name_04', text: 'Кайтсерфiнг' },
			{ url: slide5, lng: 'lng-name_05', text: 'САП серфінг' },
		],
	};

	render() {
		return (
			<div id='slider'>
				{this.state.images.map((item, index) => {
					return (
						<SlideItem
							key={index}
							url={item.url}
							lng={item.lng}
							text={item.text}
						/>
					);
				})}
			</div>
		);
	}
}

export default LinesSlider;
