/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';

import viber from '../../_img/viber.png';
import telegram from '../../_img/telegram.png';
import insta from '../../_img/insta.png';

import sl1 from './slide1.jpg';
import sl2 from './slide2.jpg';
import sl3 from './slide3.jpg';
import sl4 from './slide4.jpg';
import sl5 from './slide5.jpg';
import sl6 from './slide6.jpg';
import sl7 from './slide7.jpg';

import Lines from '../../components/Lines/Lines';

SwiperCore.use([Navigation, Pagination, EffectFade]);

class ComeWithUs extends React.Component {
	render() {
		return (
			<section id='come-with-us' className='come-with-us'>
				<div className='container'>
					<div className='wrap df fww'>
						<div className='left'>
							<h2 className='lng-come_with_us_h2'>Давай з нами</h2>
							<div className='wrapper'>
								<p className='lng-come_with_us_p1'>
									Наші тренери та менеджери чекають на ваш вибір! Приєднуйтесь
									до нашої команди серфінгу і ви отримаєте надзвичайні враження!
								</p>
								<div className='mess df'>
									<a href='#'>
										<img className='bw' src={viber} alt='viber' />
									</a>
									<a href='#'>
										<img className='bw' src={telegram} alt='telegram' />
									</a>
									<a href='#'>
										<img className='bw' src={insta} alt='insta' />
									</a>
								</div>
								{/* Navigation buttons */}
								<div className='prev swiper-button-prev'></div>
								<div className='next swiper-button-next'></div>
							</div>
						</div>
						<div className='right'>
							{/* Slider Down container */}
							<div className='slider-down'>
								<Swiper
									loop={true}
									navigation={{
										nextEl: '.swiper-button-next',
										prevEl: '.swiper-button-prev',
									}}
									pagination={{ clickable: true, el: '.swiper-pagination' }}
									effect={'fade'}>
									{[sl2, sl3, sl4, sl5, sl6, sl7, sl1].map((i, el) => {
										return (
											<SwiperSlide key={`slide${el + 1}`}>
												<div className='img-box'>
													<img src={`${i}`} alt={`slide${el + 1}`} />
												</div>
											</SwiperSlide>
										);
									})}
								</Swiper>
							</div>

							{/* Slider Upper container */}
							<div className='slider-upper'>
								<Swiper
									loop={true}
									navigation={{
										nextEl: '.swiper-button-next',
										prevEl: '.swiper-button-prev',
									}}
									pagination={{ clickable: true, el: '.swiper-pagination' }}
									effect={'fade'}>
									{[sl1, sl2, sl3, sl4, sl5, sl6, sl7].map((i, el) => {
										return (
											<SwiperSlide key={`slide${el + 1}`}>
												<div className='img-box'>
													<img src={`${i}`} alt={`slide${el + 1}`} />
												</div>
											</SwiperSlide>
										);
									})}

									{/* Pagination */}
									<div className='swiper-pagination'></div>
								</Swiper>
							</div>
						</div>
					</div>
				</div>

				<Lines />
			</section>
		);
	}
}

export default ComeWithUs;
