import React from 'react';
import beachImg from './bg-sec7.jpg';

class Tours extends React.Component {
	render() {
		return (
			<section id='tours' className='tours'>
				<div className='container'>
					<div className='wrap df fww'>
						<div className='left'>
							<h2 className='lng-tours_h2'>Cерфiнг-тури</h2>
							<div className='wrapper'>
								<p className='lng-tours_p1'>
									Serf Spot пропонує всiм фанатам серфінга i не тільки їм,
									найвідомі курорти та місця великих океанських хвиль та
									найдзвичайних вражень у всьому світі!
								</p>
								<p className='lng-tours_p2'>
									Ми знаємо все про організацію та навчання серфінгу за кордоном
									від дитини до дорослого!
								</p>
							</div>
						</div>
						<div className='right'>
							<div className='wrap'>
								<img src={beachImg} alt='men on beach' />
							</div>
						</div>
					</div>

					<div className='items'>
						<div className='wrap df fww'>
							<div className='item'>
								<div className='df'>
									<h4 className='lng-tours_h4_1'>Австралія</h4>
									<span className='lng-tours_span1'>від 4200$</span>
								</div>
								<div className='box'>
									<ul>
										<li>Gold Coast</li>
										<li>Great Ocean Road</li>
										<li>Long Rif Beach</li>
										<li>New Port Beach</li>
										<li>Limestone Coast</li>
									</ul>
									<button className='btn lng-tours_btn1' data-modal>
										Хочу це!
									</button>
								</div>
							</div>

							<div className='item'>
								<div className='df'>
									<h4 className='lng-tours_h4_2'>Америка</h4>
									<span className='lng-tours_span2'>від 3500$</span>
								</div>
								<div className='box'>
									<ul>
										<li>California</li>
										<li>Los Angeles</li>
										<li>San Diego</li>
										<li>San Francisco</li>
										<li>Mavericks</li>
									</ul>
									<button className='btn lng-tours_btn2' data-modal>
										Хочу це!
									</button>
								</div>
							</div>

							<div className='item'>
								<div className='df'>
									<h4 className='lng-tours_h4_3'>Європа</h4>
									<span className='lng-tours_span3'>від 2700$</span>
								</div>
								<div className='box'>
									<ul>
										<li>Sort-Osgaard</li>
										<li>Biarritz</li>
										<li>San Sebastian</li>
										<li>Peniche</li>
										<li>Ericeira</li>
									</ul>
									<button className='btn lng-tours_btn3' data-modal>
										Хочу це!
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Tours;
