import React from 'react';

import onWave from './on-wave.png';
import yourSpaceBg from './bg-sec2.jpg';

class YourSpace extends React.Component {
	render() {
		return (
			<section id='your-space' className='your-space'>
				<div className='container'>
					<img className='on-wave' src={onWave} alt='text on wave' />
					<div className='wrap df fww'>
						<div className='left'>
							<h2 className='lng-your_space_h2'>
								Surf Spot -<br />
								місце твого щастя
							</h2>
							<div className='wrapper'>
								<p className='lng-your_space_p1'>
									Відтепер Вінниця - це місто серфінгістів!
								</p>
								<p className='lng-your_space_p2'>
									Звучить як прикол? А от і ні! Адже ми придбали катер, привезли
									його до Вінниці, створили локацію, запросили професійних
									пілотів та тренерів - й відкрили перший Surf Spot у місті!
								</p>
								<p className='lng-your_space_p3'>
									І ми запрошуємо тебе долучитися до нового виду спорту,
									відпочинку та дозвілля у Вінниці. Не потрібно летіти на Балі
									або їхати до Києва. Тепер зловити хвилю можна й в нашому місті
									- після роботи чи навіть в обідню перерву!
								</p>
								<p className='lng-your_space_p4'>
									Філософія Surf Spot - бути щасливим тут і зараз. Приходьте з
									колегами, друзями та сім'ями, навіть якщо вони не будуть
									серфити, то відпочинуть просто катаючись на катері. І їм
									відпочинок, й для тебе вища хвиля від навантаженого катера!
								</p>
							</div>
						</div>
						<div className='right'>
							<div className='wrap'>
								<img src={yourSpaceBg} alt='car' />
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default YourSpace;
