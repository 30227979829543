import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import App from './App/App.js';
import changeLang from './Functions/change_lang';
import modalWindow from './Functions/modal';
import linesSlider from './Functions/lines_slider';
import surfingTabs from './Functions/tabs';
import scroll from './Functions/scroll';
import upperArrow from './Functions/upper_arrow';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// Custom functions
changeLang();
modalWindow();
linesSlider();
surfingTabs();
scroll();
upperArrow();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
