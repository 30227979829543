import React from 'react';

import Lines from '../../components/Lines/Lines';
import sun from './sun.png';

class FirstTask extends React.Component {
	render() {
		return (
			<section id='first-task' className='first-task'>
				<Lines />
				<div className='container'>
					<img className='sun' src={sun} alt='sun' />
					<div className='wrap df fww'>
						<div className='left'>
							<h2 className='lng-first_task_h2'>Перше заняття</h2>
							<div className='wrapper'>
								<p className='lng-first_task_p1'>
									Давай відверто, якщо ти вперше стаєш на дошку, то краще це
									робити з нашим тренером. Бо ми хочемо, щоб ти отримав
									задоволення вже від першої катки та приходив до нас знову і
									знову. А для цього - потрібен коуч!
								</p>
								<p className='lng-first_task_p2'>
									Тож роби свій вибір: витрачати час на спроби встояти на дошці,
									чи кататись із тренером в кайф вже з першого разу?
								</p>
							</div>
						</div>
						<div className='right'>
							<div className='price-list'>
								<h3>Green wave</h3>
								<div className='box'>
									<p className='lng-first_task_p3'>
										Урок з тренером для початківців, який більше про практику, а
										менше про теорію
									</p>
									<p className='lng-first_task_p4'>В нього входить:</p>
									<ul className='df fww'>
										<li className='lng-first_task_li1'>Теоретичний курс</li>
										<li className='lng-first_task_li2'>Вправи на березі</li>
										<li className='lng-first_task_li3'>Інструктаж у воді</li>
										<li className='lng-first_task_li4'>
											15 хвилин катання за катером
										</li>
									</ul>
									<hr />
									<div className='box-down df'>
										<p className='lng-first_task_p5'>1350 грн</p>
										<div>
											<button className='btn lng-first_task_btn' data-modal>
												Кататись в кайф!
											</button>
											<span className='lng-first_task_span1'>
												Тривалість заняття - 30 хв
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className='price-min df'>
								<p className='lng-first_task_p6'>Ціна додаткової хвилини</p>
								<span className='lng-first_task_span2'>65 грн</span>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default FirstTask;
