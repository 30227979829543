import React from 'react';

const CardItem = (props) => {
	return (
		<React.Fragment>
			<div className='item'>
				<div className='df'>
					<h4>{props.title}</h4>
					<span className={props.lngSpan}>{props.spanText}</span>
				</div>
				<div className='box'>
					<p className={props.lngP}>{props.pText}</p>
					<button className={props.lngBtn} data-modal>
						Хочу це!
					</button>
					<span className={props.lngSpan2}>{props.span2Text}</span>
				</div>
			</div>
		</React.Fragment>
	);
};

class Subscriptions extends React.Component {
	state = {
		cards: [
			{
				title: 'Surf Lite',
				lngSpan: 'lng-subscriptions_span1',
				spanText: '100 хв',
				lngP: 'lng-subscriptions_p3',
				lngBtn: 'btn lng-subscriptions_btn',
				pText: '6 300 грн',
				lngSpan2: 'lng-subscriptions_span2',
				span2Text: '63 грн/хв',
			},
			{
				title: 'Surf Pro',
				lngSpan: 'lng-subscriptions_span3',
				spanText: '200 хв',
				lngP: 'lng-subscriptions_p4',
				lngBtn: 'btn lng-subscriptions_btn2',
				pText: '12 200 грн',
				lngSpan2: 'lng-subscriptions_span42',
				span2Text: '62 грн/хв',
			},
			{
				title: 'Surf Boss',
				lngSpan: 'lng-subscriptions_span5',
				spanText: '500 хв',
				lngP: 'lng-subscriptions_p5',
				lngBtn: 'btn lng-subscriptions_btn3',
				pText: '30 000 грн',
				lngSpan2: 'lng-subscriptions_span6',
				span2Text: '60 грн/хв',
			},
		],
	};

	render() {
		return (
			<section id='subscriptions' className='subscriptions'>
				<div className='container'>
					<div className='title'>
						<h2 className='lng-subscriptions_h2'>Абонементи</h2>
						<div className='wrapper'>
							<p className='lng-subscriptions_p1'>
								Звичайно, серфінг то про задоволення, а його важко оцінити. Але
								ми зробили так,  щоб ти міг кататись ще й з фінансовою вигодою
							</p>
							<p className='lng-subscriptions_p2'>
								Формула проста: чим більший абонемент, тим дешевше коштує
								хвилина. Ніяких складнощів, тільки катка!
							</p>
						</div>
					</div>
					<div className='wrap df fww'>
						{this.state.cards.map((item, index) => {
							return (
								<CardItem
									key={index}
									title={item.title}
									lngSpan={item.lngSpan}
									spanText={item.spanText}
									lngP={item.lngP}
									lngBtn={item.lngBtn}
									pText={item.pText}
									lngSpan2={item.lngSpan2}
									span2Text={item.span2Text}
								/>
							);
						})}
					</div>

					<div className='wrap-down df'>
						<div className='left'>
							<h3 className='lng-subscriptions_h3'>Покататися з тренером</h3>
							<p className='lng-subscriptions_p6'>
								Окей, ти вже пройшов перше заняття і придбав абонемент. Вітаємо
								у нашій команді! Але тримай пораду: краще та з більшим
								задоволенням ти викатаєш свої хвилини, коли поруч буде тренер.
								Він підстрахує та допоможе вивчити нові елементи. Катай
								ефективно!
							</p>
						</div>
						<div className='right'>
							<span className='lng-subscriptions_span7'>350 грн/заняття</span>
							<button className='btn lng-subscriptions_btn4' data-modal>
								Давайте тренера!
							</button>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Subscriptions;
