/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import UpperArrow from '../components/Fixed-btns/Upper-arrow';
import PhoneBtn from '../components/Fixed-btns/Phone-btn';

import close from './close.png';

class Footer extends React.Component {
	render() {
		return (
			<React.Fragment>
				<footer>
					<div className='container df fww'>
						<p>Copyright © 2021 | All rights reserved</p>
						<a href='#'>
							Design by <b>Rabbitmarketing</b>
						</a>
						<a href='https://adbbox.com/' target='_blank'>
							Development by <b>adbbox.com</b>
						</a>
					</div>
				</footer>

				{/* Modal Window */}
				<div className='modal window' style={{ display: 'none' }}>
					<button className='modal-close' data-close>
						<img src={close} alt='modal-close' />
					</button>
					<div className='modal-box a-center'>
						<form id='form'>
							<h4 className='lng-modal_h4'>Залишити заявку</h4>
							<label>
								<input type='text' name='name' id='name' required />
								<div className='label-text lng-modal_label1'>Iм'я</div>
							</label>
							<label>
								<input type='tel' name='phone' id='phone' required />
								<div className='label-text lng-modal_label2'>Телефон</div>
							</label>
							<button className='btn lng-modal_btn1' type='submit'>
								Подзвоніть мені!
							</button>
						</form>
					</div>
				</div>

				<UpperArrow />
				<PhoneBtn />
			</React.Fragment>
		);
	}
}

export default Footer;
