import React from 'react';

import logo from '../_img/logo.png';
import Lang from '../components/Lang/Lang';

class Header extends React.Component {
	render() {
		return (
			<header id='header' className='header'>
				<div className='wrap'>
					<div className='container df'>
						<a className='logo scroll' href='#header'>
							<img src={logo} alt='logo' />
						</a>
						<div className='df btns'>
							<Lang />
							<button className='btn lng-btn' data-modal>
								Залишити заявку
							</button>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;
