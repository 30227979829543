export const langArr = {
	btn: {
		ru: 'Оставить заявку',
		en: 'Leave request',
		ua: 'Залишити заявку',
	},
	your_space_h2: {
		ru: 'Surf Spot -<br>место твоего счастья',
		en: 'Surf Spot -<br>place of your happiness',
		ua: 'Surf Spot -<br>місце твого щастя',
	},
	your_space_p1: {
		ru: 'Отныне Винница - это город серфингистов!',
		en: 'From now on Vinnytsia is a city of surfers!',
		ua: 'Відтепер Вінниця - це місто серфінгістів!',
	},
	your_space_p2: {
		ru: 'Звучит как прикол? А вот и нет! Ведь мы приобрели катер, привезли его в Винницу, создали локацию, пригласили профессиональных пилотов и тренеров - и открыли первый Surf Spot в городе!',
		en: 'Sounds like a joke? But no! After all, we bought a boat, brought it to Vinnytsia, created a location, invited professional pilots and coaches - and opened the first Surf Spot in the city!',
		ua: 'Звучить як прикол? А от і ні! Адже ми придбали катер, привезли його до Вінниці, створили локацію, запросили професійних пілотів та тренерів - й відкрили перший Surf Spot у місті!',
	},
	your_space_p3: {
		ru: 'И мы приглашаем тебя присоединиться к новому виду спорта, отдыха и досуга в Виннице. Не нужно лететь на Бали или ехать в Киев. Теперь поймать волну можно и в нашем городе - после работы или даже в обеденный перерыв!',
		en: 'And we invite you to join a new sport, recreation and leisure in Vinnytsia. You do not need to fly to Bali or go to Kiev. Now you can catch a wave in our city - after work or even during the lunch break!',
		ua: 'І ми запрошуємо тебе долучитися до нового виду спорту, відпочинку та дозвілля у Вінниці. Не потрібно летіти на Балі або їхати до Києва. Тепер зловити хвилю можна й в нашому місті - після роботи чи навіть в обідню перерву!',
	},
	your_space_p4: {
		ru: 'Философия Surf Spot - быть счастливым здесь и сейчас. Приходите с коллегами, друзьями и семьями, даже если они не будут серфить, то отдохнут просто катаясь на катере. И им отдых, и для тебя выше волна от нагруженного катера!',
		en: 'The philosophy of Surf Spot is to be happy here and now. Come with colleagues, friends and family, even if they are not surfers, they will relax just by boating. And they have a rest, and for you the highest wave from the loaded boat!',
		ua: "Філософія Surf Spot - бути щасливим тут і зараз. Приходьте з колегами, друзями та сім'ями, навіть якщо вони не будуть серфити, то відпочинуть просто катаючись на катері. І їм відпочинок, й для тебе вища хвиля від навантаженого катера!",
	},
	first_task_h2: {
		ru: 'Первое занятие',
		en: 'The first lesson',
		ua: 'Перше заняття',
	},
	first_task_p1: {
		ru: 'Давай откровенно, если ты впервые становишься на доску, то лучше это делать с нашим тренером. Потому что мы хотим, чтобы ты получил удовольствие уже от первой катки и приходил к нам снова и снова. А для этого - нужно коуч!',
		en: 'Frankly, if you are on the board for the first time, it is better to do it with our coach. Because we want you to enjoy the first rink and come to us again and again. And for this - you need a coach!',
		ua: 'Давай відверто, якщо ти вперше стаєш на дошку, то краще це робити з нашим тренером. Бо ми хочемо, щоб ти отримав задоволення вже від першої катки та приходив до нас знову і знову. А для цього - потрібен коуч!',
	},
	first_task_p2: {
		ru: 'Поэтому делай свой выбор: тратить время на попытки устоять на доске, или кататься с тренером в кайф уже с первого раза?',
		en: 'So make your choice: spend time trying to stand on the board, or ride with a coach for the first time?',
		ua: 'Тож роби свій вибір: витрачати час на спроби встояти на дошці, чи кататись із тренером в кайф вже з першого разу?',
	},
	first_task_p3: {
		ru: 'Урок с тренером для начинающих, который больше о практике, а меньше о теории',
		en: 'A lesson with a beginner coach that is more about practice and less about theory',
		ua: 'Урок з тренером для початківців, який більше про практику, а менше про теорію',
	},
	first_task_p4: {
		ru: 'В него входит:',
		en: 'It includes:',
		ua: 'В нього входить:',
	},
	first_task_li1: {
		ru: 'Теоретический курс',
		en: 'Theoretical course',
		ua: 'Теоретичний курс',
	},
	first_task_li2: {
		ru: 'Упражнения на берегу',
		en: 'Exercises on the beach',
		ua: 'Вправи на березі',
	},
	first_task_li3: {
		ru: 'Инструктаж в воде',
		en: 'Instruction in the water',
		ua: 'Інструктаж у воді',
	},
	first_task_li4: {
		ru: '15 минут катания за катером',
		en: '15 minutes by boat',
		ua: '15 хвилин катання за катером',
	},
	first_task_p5: {
		ru: '1350 грн',
		en: '1350 uah',
		ua: '1350 грн',
	},
	first_task_btn: {
		ru: 'Кататься в кайф!',
		en: 'Ride in high spirits!',
		ua: 'Кататись в кайф!',
	},
	first_task_span1: {
		ru: 'Продолжительность занятия - 30 мин',
		en: 'Class duration - 30 minutes',
		ua: 'Тривалість заняття - 30 хв',
	},
	first_task_p6: {
		ru: 'Цена дополнительной минуты',
		en: 'The price of an extra minute',
		ua: 'Ціна додаткової хвилини',
	},
	first_task_span2: {
		ru: '65 грн',
		en: '65 uah',
		ua: '65 грн',
	},
	types_of_surfing_h2: {
		ru: 'Виды серфинга',
		en: 'Types of surfing',
		ua: 'Види серфінгу',
	},
	types_of_surfing_p1: {
		ru: 'Серфинг (англ. <i>Surfing</i>) - скольжение по водной поверхности на легкой доске, возможно с применением специальных средств. Многие уже забыли о том, что серфинг является древнейшим спортом на Земле, существующий уже много тысячелетий и доступный ранее только королям. В зависимости от типа, размера доски и применяемых технических средствах различают виды серфинга:',
		en: 'Surfing - sliding on the water surface on a light board, possibly with the use of special tools. Many have forgotten that surfing is the oldest sport on Earth, existing for many millennia and previously available only to kings. Depending on the type, size of the board and the technical means used, there are types of surfing:',
		ua: 'Серфінг (англ. <i>Surfing</i>) - ковзання по водній поверхні на легкої дошці, можливо із застосуванням спеціальних засобів. Багато вже забули про те, що серфінг є найдавнішим спортом на Землі, існуючий вже багато тисячоліть і доступний раніше тільки королям. Залежно від типу, розміру дошки і застосовуваних технічних засобах розрізняють види серфінгу:',
	},
	name_01: {
		ru: 'Cерфинг',
		en: 'Surfing',
		ua: 'Серфiнг',
	},
	name_02: {
		ru: 'Виндсерфинга',
		en: 'Windsurfing',
		ua: 'Вiндсерфiнг',
	},
	name_03: {
		ru: 'Вейксерфинг',
		en: 'Wakesurfing',
		ua: 'Вейксерфiнг',
	},
	name_04: {
		ru: 'Кайтсерфинг',
		en: 'Kitesurfing',
		ua: 'Кайтсерфiнг',
	},
	name_05: {
		ru: 'САП серфинг',
		en: 'SUP surfing',
		ua: 'САП серфінг',
	},
	name_01_1: {
		ru: 'Cерфинг',
		en: 'Surfing',
		ua: 'Серфiнг',
	},
	name_02_1: {
		ru: 'Виндсерфинга',
		en: 'Windsurfing',
		ua: 'Вiндсерфiнг',
	},
	name_03_1: {
		ru: 'Вейксерфинг',
		en: 'Wakesurfing',
		ua: 'Вейксерфiнг',
	},
	name_04_1: {
		ru: 'Кайтсерфинг',
		en: 'Kitesurfing',
		ua: 'Кайтсерфiнг',
	},
	name_05_1: {
		ru: 'САП серфинг',
		en: 'SUP surfing',
		ua: 'САП серфінг',
	},
	name_01_2: {
		ru: 'Cерфинг',
		en: 'Surfing',
		ua: 'Серфiнг',
	},
	name_02_2: {
		ru: 'Виндсерфинга',
		en: 'Windsurfing',
		ua: 'Вiндсерфiнг',
	},
	name_03_2: {
		ru: 'Вейксерфинг',
		en: 'Wakesurfing',
		ua: 'Вейксерфiнг',
	},
	name_04_2: {
		ru: 'Кайтсерфинг',
		en: 'Kitesurfing',
		ua: 'Кайтсерфiнг',
	},
	name_05_2: {
		ru: 'САП серфинг',
		en: 'SUP surfing',
		ua: 'САП серфінг',
	},
	types_of_surfing_tab_pane1: {
		ru: 'В классическом понимании, означает скольжение на прибрежной волны только на доске. Доска и техника скольжения может быть разной: можно серфить стоя на длинных 2-3 метровых досках - <i>longboard</i>, стоя на коротких досках, размером с человеческий рост - <i>shortboard</i> или лежа на маленьких , легких прямоугольных досках - <i>bodyboard</i>',
		en: 'In the classical sense, it means sliding on a coastal wave only on a board. Board and sliding technique can be different: you can surf standing on long 2-3 meter boards - <i>longboard</i>, standing on short boards, the size of a man - <i>shortboard</i> or lying on small , light rectangular boards - <i>bodyboard</i>',
		ua: 'У класичному розумінні, означає ковзання на прибережній хвилі тільки на дошці. Дошка та техніка ковзання може бути різною: можна серфить стоячи на довгих 2-3 метрових дошках - <i>longboard</i>, стоячи на коротких дошках, розміром з людський зріст - <i>shortboard</i> або лежачи на маленьких, легких прямокутних дошках - <i>bodyboard</i>',
	},
	types_of_surfing_tab_pane2: {
		ru: 'Из названия <i>виндсерфинг</i> (<i>wind</i> - "ветер", <i>surfing</i> - "скольжения") понятно, что движение не только на доске, но и с использованием силы ветра, который влияет на доску благодаря паруса, присоединенном к доске. Управление доской работает по всем законам парусного спорта, плюс влияния скоростной мощности воды, усиливает количества адреналина в крови катальщика и делает сам процесс очень зрелищным',
		en: 'From the name <i>windsurfing</i> it is clear that the movement is not only on the board, but also using the force of the wind that affects the board due to the sail attached to the board. Board management works according to all the laws of sailing, plus the impact of high-speed water power, increases the amount of adrenaline in the blood of the skater and makes the process very spectacular',
		ua: 'З назви <i>віндсерфінг</i> (<i>wind</i> - "вітер", <i>surfing</i> - "ковзання") зрозуміло, що рух не тільки на дошці, а й з використанням сили вітру, який впливає на дошку завдяки вітрила, приєднаному до дошки. Управління дошкою працює за всіма законами парусного спорту, плюс впливу швидкісний потужності води, підсилює кількості адреналіну в крові катальщика і робить сам процес дуже видовищним',
	},
	types_of_surfing_tab_pane3: {
		ru: '<i>Вейксерфинг</i> (<i>wakesurf</i>) - разновидность серфинга, скольжение по волне на небольшой доске за катером-буксировщиком. Катер грузится балластом и может создавать волну до одного метра. Для профессиональных спортсменов <i>wakesurf</i> изначально являлся симулятором классического серфа в условиях отсутствия ветра и волны',
		en: '<i>Wakesurf</i> - a kind of surfing, gliding on a wave on a small board behind a tugboat. The boat is loaded with ballast and can create a wave of up to one meter. For professional athletes <i>wakesurf</i> from the beginning acted as a simulator of classic surfing in the absence of wind and waves',
		ua: '<i>Вейксерфінг</i> (<i>wakesurf</i>) - різновид серфінгу, ковзання по хвилі на невеликій дошці за катером-буксировщиком. Катер вантажиться баластом і може створювати хвилю до одного метра. Для професійних спортсменів <i>wakesurf</i> від початку виступав симулятором класичного серфа в умовах відсутності вітру і хвилі',
	},
	types_of_surfing_tab_pane4: {
		ru: '<i>Кайтсерфинг</i> очень молодой вид спорта, основой которого является движение на доске под действием силы тяги, развиваемой управляемым спортсменом воздушным змеем - <i>кайтом</i>. Достаточно сложное обучение, но максимальный быстрый прогресс в развитии райдера, делают <i>кайтсерфинг</i> все более популярным спортом',
		en: '<i>Kitesurfing</i> is a very young sport, the basis of which is the movement on the board under the action of traction, which is developed by an athlete-controlled kite. Quite difficult training, but the maximum rapid progress in the development of the rider, make <i>kitesurfing</i> an increasingly popular sport',
		ua: '<i>Кайтсерфінг</i> дуже молодий вид спорту, основою якого є рух на дошці під дією сили тяги, що розвивається керованим спортсменом повітряним змієм - <i>кайтом</i>. Досить складне навчання, але максимальний швидкий прогрес у розвитку райдера, роблять <i>кайтсерфінг</i> все більш популярним спортом',
	},
	types_of_surfing_tab_pane5: {
		ru: '<i>Кайтсерфинг</i> очень молодой вид спорта, основой которого является движение на доске под действием силы тяги, развиваемой управляемым спортсменом воздушным змеем - <i>кайтом</i>. Достаточно сложное обучение, но максимальный быстрый прогресс в развитии райдера, делают <i>кайтсерфинг</i> все более популярным спортом',
		en: '<i>Kitesurfing</i> is a very young sport, the basis of which is the movement on the board under the action of traction, which is developed by an athlete-controlled kite. Quite difficult training, but the maximum rapid progress in the development of the rider, make <i>kitesurfing</i> an increasingly popular sport',
		ua: '<i>Кайтсерфінг</i> дуже молодий вид спорту, основою якого є рух на дошці під дією сили тяги, що розвивається керованим спортсменом повітряним змієм - <i>кайтом</i>. Досить складне навчання, але максимальний швидкий прогрес у розвитку райдера, роблять <i>кайтсерфінг</i> все більш популярним спортом',
	},
	subscriptions_h2: {
		ru: 'Абонементы',
		en: 'Subscriptions',
		ua: 'Абонементи',
	},
	subscriptions_p1: {
		ru: 'Конечно, серфинг то об удовольствии, а его трудно оценить. Но мы сделали так, чтобы ты мог кататься еще и с финансовой выгодой',
		en: "Of course, surfing is about fun, and it's hard to appreciate. But we made it so that you could ride with financial benefits",
		ua: 'Звичайно, серфінг то про задоволення, а його важко оцінити. Але ми зробили так,  щоб ти міг кататись ще й з фінансовою вигодою',
	},
	subscriptions_p2: {
		ru: 'Формула проста: чем больше абонемент, тем дешевле стоит минута. Никаких сложностей, только катка!',
		en: 'The formula is simple: the bigger the season ticket, the cheaper a minute. No difficulties, just a skating rink!',
		ua: 'Формула проста: чим більший абонемент, тим дешевше коштує хвилина. Ніяких складнощів, тільки катка!',
	},
	subscriptions_span1: {
		ru: '100 мин',
		en: '100 min',
		ua: '100 хв',
	},
	subscriptions_p3: {
		ru: '6 300 грн',
		en: '6 300 uah',
		ua: '6 300 грн',
	},
	subscriptions_span2: {
		ru: '63 грн/мин',
		en: '63 uah/min',
		ua: '63 грн/хв',
	},
	subscriptions_btn: {
		ru: 'Хочу это!',
		en: 'I want it!',
		ua: 'Хочу це!',
	},
	subscriptions_span3: {
		ru: '200 мин',
		en: '200 min',
		ua: '200 хв',
	},
	subscriptions_p4: {
		ru: '12 200 грн',
		en: '12 200 uah',
		ua: '12 200 грн',
	},
	subscriptions_btn2: {
		ru: 'Хочу это!',
		en: 'I want it!',
		ua: 'Хочу це!',
	},
	subscriptions_span4: {
		ru: '62 грн/мин',
		en: '62 uah/min',
		ua: '62 грн/хв',
	},
	subscriptions_span5: {
		ru: '500 мин',
		en: '500 min',
		ua: '500 хв',
	},
	subscriptions_p5: {
		ru: '30 000 грн',
		en: '30 000 uah',
		ua: '30 000 грн',
	},
	subscriptions_btn3: {
		ru: 'Хочу это!',
		en: 'I want it!',
		ua: 'Хочу це!',
	},
	subscriptions_span6: {
		ru: '60 грн/мин',
		en: '60 uah/min',
		ua: '60 грн/хв',
	},
	subscriptions_h3: {
		ru: 'Ездить с тренером',
		en: 'Ride with a coach',
		ua: 'Покататися з тренером',
	},
	subscriptions_p6: {
		ru: 'Окей, ты уже прошел первое занятие и приобрел абонемент. Добро пожаловать в нашей команде! Но держи совет: лучше и с большим удовольствием ты викатаеш свои минуты, когда рядом будет тренер. Он подстрахует и поможет изучить новые элементы. Катай эффективно!',
		en: "Okay, you have already passed the first lesson and bought a season ticket. Congratulations to our team! But keep the advice: it's better and with more pleasure you roll out your minutes when the coach is nearby. It will insure and help you learn new elements. Ride effectively!",
		ua: 'Окей, ти вже пройшов перше заняття і придбав абонемент. Вітаємо у нашій команді! Але тримай пораду: краще та з більшим задоволенням ти викатаєш свої хвилини, коли поруч буде тренер. Він підстрахує та допоможе вивчити нові елементи. Катай ефективно!',
	},
	subscriptions_span7: {
		ru: '350 грн/заятие',
		en: '350 uah/lesson',
		ua: '350 грн/заняття',
	},
	subscriptions_btn4: {
		ru: 'Давайте тренера!',
		en: 'Give the coach!',
		ua: 'Давайте тренера!',
	},
	buy_forgive_h2: {
		ru: 'Покупай и подари!',
		en: 'Buy and forgive!',
		ua: 'Купуй і даруй!',
	},
	buy_forgive_p1: {
		ru: 'Пришел сам - приведи друга! Самый простой способ - подари ему занятие или абонемент. И тебе веселее будет катать с компанией, и вопрос, что же необычного подарить решишь!',
		en: 'I came myself - bring a friend! The easiest way is to give him a lesson or a season ticket. And you will have more fun riding with the company, and the question of what is unusual to give a solution!',
		ua: 'Прийшов сам - приведи друга! Найпростіший спосіб - подаруй йому заняття чи абонемент. І тобі веселіше буде катати з компанією, і питання, що ж незвичайного подарувати вирішиш!',
	},
	buy_forgive_p2: {
		ru: 'Это же касается и девушек: хочешь все выходные кататься на катере? Даруй любимому абонемент! Win-Win!',
		en: 'The same applies to girls: do you want to ride a boat all weekend? Give your loved one a season ticket! Win-Win!',
		ua: 'Це ж стосується і дівчат: хочеш всі вихідні кататись на катері? Даруй коханому абонемент! Win-Win!',
	},
	tours_h2: {
		ru: 'Cерфинг-туры',
		en: 'Surfing tours',
		ua: 'Серфiнг-тури',
	},
	tours_p1: {
		ru: 'Serf Spot предлагает всем фанатам серфинга i не только им, извест курорты и места больших океанских волн и найдзвичайних впечатлений во всем мире!',
		en: 'Serf Spot offers all surfing fans and not only them, the most famous resorts and places of big ocean waves and the most extraordinary impressions around the world!',
		ua: 'Serf Spot пропонує всiм фанатам серфінга i не тільки їм, найвідомі курорти та місця великих океанських хвиль та найдзвичайних вражень у всьому світі!',
	},
	tours_p2: {
		ru: 'Мы знаем об организации и обучения серфингу за рубежом от ребенка к взрослому!',
		en: 'We know everything about the organization and training of surfing abroad from child to adult!',
		ua: 'Ми знаємо все про організацію та навчання серфінгу за кордоном від дитини до дорослого!',
	},
	tours_h4_1: {
		ru: 'Австралия',
		en: 'Australia',
		ua: 'Австралія',
	},
	tours_span1: {
		ru: 'от 4200$',
		en: 'from 4200$',
		ua: 'від 4200$',
	},
	tours_btn1: {
		ru: 'Хочу это!',
		en: 'I want it!',
		ua: 'Хочу це!',
	},
	tours_h4_2: {
		ru: 'Америка',
		en: 'America',
		ua: 'Америка',
	},
	tours_span2: {
		ru: 'от 3500$',
		en: 'from 3500$',
		ua: 'від 3500$',
	},
	tours_btn2: {
		ru: 'Хочу это!',
		en: 'I want it!',
		ua: 'Хочу це!',
	},
	tours_h4_3: {
		ru: 'Европа',
		en: 'Europe',
		ua: 'Європа',
	},
	tours_span3: {
		ru: 'от 2700$',
		en: 'from 2700$',
		ua: 'від 2700$',
	},
	tours_btn3: {
		ru: 'Хочу это!',
		en: 'I want it!',
		ua: 'Хочу це!',
	},
	come_with_us_h2: {
		ru: 'Давай с нами',
		en: 'Come with us',
		ua: 'Давай з нами',
	},
	come_with_us_p1: {
		ru: 'Наши тренеры и менеджеры ждут ваш выбор! Присоединяйтесь к нашей команде серфинга и вы получите необычайные впечатления!',
		en: 'Our coaches and managers are waiting for your choice! Join our surfing team and you will get an extraordinary experience!',
		ua: 'Наші тренери та менеджери чекають на ваш вибір! Приєднуйтесь до нашої команди серфінгу і ви отримаєте надзвичайні враження!',
	},
	contacts_title: {
		ru: 'контакты',
		en: 'contacts',
		ua: 'контакти',
	},
	contacts_span: {
		ru: 'г.Винница, ул.Князей Кориатовичей,23',
		en: 'Vinnytsia, Knyaziv Koriatovychiv str., 23',
		ua: 'м.Винниця, вул.Князів Коріатовичів,23',
	},
	modal_h4: {
		ru: 'Оставить заявку',
		en: 'Leave request',
		ua: 'Залишити заявку',
	},
	modal_label1: {
		ru: 'Имя',
		en: 'Name',
		ua: "Iм'я",
	},
	modal_label2: {
		ru: 'Телефон',
		en: 'Phone',
		ua: 'Телефон',
	},
	modal_btn1: {
		ru: 'Позвоните мне!',
		en: 'Call me!',
		ua: 'Подзвоніть мені!',
	},
	modal_p1: {
		ru: 'Сообщение отправлено!',
		en: 'Message sent!',
		ua: 'Повідомлення надіслано!',
	},
	modal_btn2: {
		ru: 'Закрыть',
		en: 'Close',
		ua: 'Закрити',
	},
};
