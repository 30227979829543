export default function upperArrow() {
	window.onscroll = () => {
		const scrolled = window.pageYOffset || document.documentElement.scrollTop;

		if (scrolled > 1500) {
			document.querySelector('#upper').style.display = 'block';
		} else {
			document.querySelector('#upper').style.display = 'none';
		}
	};
}
