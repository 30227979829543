import React from 'react';
import LinesSlider from '../../components/Lines-Slider/Slider';
import Tabs from '../../components/Tabs/Tabs';

class TypesOfSurfing extends React.Component {
	render() {
		return (
			<section id='types-of-surfing' className='types-of-surfing'>
				<div className='container'>
					<div id='title'>
						<h2 className='lng-types_of_surfing_h2'>Види серфінгу</h2>
						<div className='wrapper'>
							<p className='lng-types_of_surfing_p1'>
								Серфінг (англ. <i>Surfing</i>) - ковзання по водній поверхні на
								легкої дошці, можливо із застосуванням спеціальних засобів.
								Багато вже забули про те, що серфінг є найдавнішим спортом на
								Землі, існуючий вже багато тисячоліть і доступний раніше тільки
								королям. Залежно від типу, розміру дошки і застосовуваних
								технічних засобах розрізняють види серфінгу:
							</p>
						</div>
					</div>
					<LinesSlider />
					<Tabs />
				</div>
			</section>
		);
	}
}

export default TypesOfSurfing;
