import React from 'react';
import Offer from './Offer/Offer';
import YourSpace from './Your-Space/Your-space';
import FirstTask from './First-Task/First-task';
import TypesOfSurfing from './Types-of-Surfing/Types-of-surfing';
import Subscriptions from './Subscriptions/Subscriptions';
import BuyForgive from './Buy-Forgive/Buy-forgive';
import Tours from './Tours/Tours';
import ComeWithUs from './Come-with-us/Come-with-us';
import Contacts from './Contacts/Contacts';

class Main extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Offer />
				<YourSpace />
				<FirstTask />
				<TypesOfSurfing />
				<Subscriptions />
				<BuyForgive />
				<Tours />
				<ComeWithUs />
				<Contacts />
			</React.Fragment>
		);
	}
}

export default Main;
