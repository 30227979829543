/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import mapImg from './map.jpg';
import telImg from './tel.png';
import instaImg from './instagram.png';
import addressImg from './mapMarker.png';

class Contacts extends React.Component {
	render() {
		return (
			<section id='contacts' className='contacts'>
				<div className='words lng-contacts_title'>контакти</div>
				<div className='map'>
					<img src={mapImg} alt='map' />
				</div>
				<div className='container a-center'>
					<div className='box'>
						<a className='df' href='#'>
							<img src={telImg} alt='tel' />
							<span>+38 (097) 555 44 11</span>
						</a>
						<a className='df' href='#' target='_blank'>
							<img src={instaImg} alt='insta' />
							<span>@SurfSpot.vn</span>
						</a>
						<a className='df' href='#' target='_blank'>
							<img src={addressImg} alt='address' />
							<span className='lng-contacts_span'>
								м.Винниця, вул.Князів Коріатовичів,23
							</span>
						</a>
					</div>
				</div>
			</section>
		);
	}
}

export default Contacts;
