/* eslint-disable no-restricted-globals */
import { langArr } from '../App/db';

export default function changeLang() {
	const selectSingle = document.querySelector('#lang');
	const select = selectSingle.querySelector('span');
	const selectLabels = selectSingle.querySelectorAll('label');

	const allLang = ['en', 'ru', 'ua'];

	// - toggle menu
	select.addEventListener('click', () => {
		if ('active' === selectSingle.getAttribute('data-state')) {
			selectSingle.setAttribute('data-state', '');
		} else {
			selectSingle.setAttribute('data-state', 'active');
		}
	});

	document.addEventListener('click', (event) => {
		if (event.target !== select) {
			selectSingle.setAttribute('data-state', '');
		}
	});

	// - select lang
	for (let i = 0; i < selectLabels.length; i++) {
		selectLabels[i].addEventListener('click', (event) => {
			select.textContent = event.target.textContent;
			selectSingle.setAttribute('data-state', '');

			let lang = select.innerHTML;
			location.href = window.location.pathname + '#' + lang;
			location.reload();
		});
	}

	function changeLanguage() {
		let hash = window.location.hash;

		hash = hash.substr(1);
		if (!allLang.includes(hash)) {
			location.href = window.location.pathname + '#ua';
			location.reload();
		}

		select.innerHTML = hash;
		for (let key in langArr) {
			let elem = document.querySelector('.lng-' + key);

			if (elem) {
				elem.innerHTML = langArr[key][hash];
			}
		}
	}

	changeLanguage();
}
