import React from 'react';

import tabsImg1 from './tabs01.jpg';
import tabsImg2 from './tabs02.jpg';
import tabsImg3 from './tabs03.jpg';
import tabsImg4 from './tabs04.jpg';
import tabsImg5 from './tabs05.jpg';

class Tabs extends React.Component {
	render() {
		return (
			<div id='types-of-surfing_tabs'>
				<ul className='tabs-nav df'>
					<li data-tab='1' className='tab-nav active lng-name_01_1'>
						Серфiнг
					</li>
					<li data-tab='2' className='tab-nav lng-name_02_1'>
						Вiндсерфiнг
					</li>
					<li data-tab='3' className='tab-nav lng-name_03_1'>
						Вейксерфiнг
					</li>
					<li data-tab='4' className='tab-nav lng-name_04_1'>
						Кайтсерфiнг
					</li>
					<li data-tab='5' className='tab-nav lng-name_05_1'>
						САП серфінг
					</li>
				</ul>

				<div className='tab-content'>
					<div data-tab-content='1' className='tab-pane active'>
						<div className='wrap df'>
							<div className='left'>
								<h2 className='lng-name_01_2'>Серфiнг</h2>
								<div className='wrapper'>
									<p className='lng-types_of_surfing_tab_pane1'>
										У класичному розумінні, означає ковзання на прибережній
										хвилі тільки на дошці. Дошка та техніка ковзання може бути
										різною: можна серфить стоячи на довгих 2-3 метрових дошках -
										<i>longboard</i>, стоячи на коротких дошках, розміром з
										людський зріст - <i>shortboard</i> або лежачи на маленьких,
										легких прямокутних дошках - <i>bodyboard</i>
									</p>
								</div>
							</div>
							<div className='right'>
								<div className='box'>
									<img className='bw' src={tabsImg1} alt='surf' />
								</div>
							</div>
						</div>
					</div>

					<div data-tab-content='2' className='tab-pane'>
						<div className='wrap df'>
							<div className='left'>
								<h2 className='lng-name_02_2'>Вiндсерфiнг</h2>
								<div className='wrapper'>
									<p className='lng-types_of_surfing_tab_pane2'>
										З назви <i>віндсерфінг</i> (<i>wind</i> - " вітер",
										<i>surfing</i> - "ковзання") зрозуміло, що рух не тільки на
										дошці, а й з використанням сили вітру, який впливає на дошку
										завдяки вітрила, приєднаному до дошки. Управління дошкою
										працює за всіма законами парусного спорту, плюс впливу
										швидкісний потужності води, підсилює кількості адреналіну в
										крові катальщика і робить сам процес дуже видовищним
									</p>
								</div>
							</div>
							<div className='right'>
								<div className='box'>
									<img className='bw' src={tabsImg2} alt='surf' />
								</div>
							</div>
						</div>
					</div>

					<div data-tab-content='3' className='tab-pane'>
						<div className='wrap df'>
							<div className='left'>
								<h2 className='lng-name_03_2'>Вейксерфiнг</h2>
								<div className='wrapper'>
									<p className='lng-types_of_surfing_tab_pane3'>
										<i>Вейксерфінг</i> (<i>wakesurf</i>) - різновид серфінгу,
										ковзання по хвилі на невеликій дошці за
										катером-буксировщиком. Катер вантажиться баластом і може
										створювати хвилю до одного метра. Для професійних
										спортсменів <i>wakesurf</i> від початку виступав симулятором
										класичного серфа в умовах відсутності вітру і хвилі
									</p>
								</div>
							</div>
							<div className='right'>
								<div className='box'>
									<img className='bw' src={tabsImg3} alt='surf' />
								</div>
							</div>
						</div>
					</div>

					<div data-tab-content='4' className='tab-pane'>
						<div className='wrap df'>
							<div className='left'>
								<h2 className='lng-name_04_2'>Кайтсерфiнг</h2>
								<div className='wrapper'>
									<p className='lng-types_of_surfing_tab_pane4'>
										<i>Кайтсерфінг</i> дуже молодий вид спорту, основою якого є
										рух на дошці під дією сили тяги, що розвивається керованим
										спортсменом повітряним змієм -<i>кайтом</i>. Досить складне
										навчання, але максимальний швидкий прогрес у розвитку
										райдера, роблять <i>кайтсерфінг</i> все більш популярним
										спортом
									</p>
								</div>
							</div>
							<div className='right'>
								<div className='box'>
									<img className='bw' src={tabsImg4} alt='surf' />
								</div>
							</div>
						</div>
					</div>

					<div data-tab-content='5' className='tab-pane'>
						<div className='wrap df'>
							<div className='left'>
								<h2 className='lng-name_05_2'>САП серфінг</h2>
								<div className='wrapper'>
									<p className='lng-types_of_surfing_tab_pane5'>
										Серфінг з Веслом (<i>Stand-up Paddling</i> або
										<i>SUP surfing</i>) один з варіантів серфінгу, де стоячи на
										великій дошці для серфінгу, для переміщення по воді
										використовується весло або невеликий парус. Даний вид спорту
										має також полінезійські корені, і носить ще назву
										<i>«Beach Boy серфінг»</i>, так як відкрили його пляжні
										хлопчики Вайкікі
									</p>
								</div>
							</div>
							<div className='right'>
								<div className='box'>
									<img className='bw' src={tabsImg5} alt='surf' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Tabs;
