import React from 'react';

const LangItem = (props) => {
	return (
		<React.Fragment>
			<input
				id={props.id}
				className='select-input'
				type='radio'
				name='singleSelect'
				defaultChecked={props.check}
			/>
			<label htmlFor={props.id} className='select-label'>
				{props.name}
			</label>
		</React.Fragment>
	);
};

class Lang extends React.Component {
	state = {
		language: [
			{ id: 'lang-0', name: 'ua', check: true },
			{ id: 'lang-1', name: 'ua', check: false },
			{ id: 'lang-2', name: 'ru', check: false },
			{ id: 'lang-3', name: 'en', check: false },
		],
	};

	render() {
		return (
			<div id='lang' data-state=''>
				<span></span>
				<div className='lang-wrap'>
					{this.state.language.map((item, index) => {
						return (
							<LangItem
								key={index}
								id={item.id}
								name={item.name}
								check={item.check}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

export default Lang;
