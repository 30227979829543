import React from 'react';

import lines from './lines.jpg';

class Lines extends React.Component {
	render() {
		return (
			<div className='lines'>
				<img src={lines} alt='lines' />
			</div>
		);
	}
}

export default Lines;
