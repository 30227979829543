import React from 'react';

import offerBg from './bg-sec1.jpg';
import offerMob from './offer-mob.jpg';

class Offer extends React.Component {
	render() {
		return (
			<section id='offer' className='offer'>
				<div className='wrap'>
					<img className='offer-bg bw' src={offerBg} alt='Man on board' />
					<img
						className='offer-mob bw'
						src={offerMob}
						alt='Man on board'
						styly={{ display: 'none' }}
					/>
				</div>
				<div className='title'>
					<div className='words'>Surf Spot</div>
				</div>
			</section>
		);
	}
}

export default Offer;
