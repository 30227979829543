import React from 'react';

export default class UpperArrow extends React.Component {
	render() {
		return (
			<div className='fixed-btn' id='upper'>
				<div className='circle-max'></div>
				<div className='circle-min'></div>
				<a href='#header' className='circle-center scroll'>
					<div className='circle-img'></div>
				</a>
			</div>
		);
	}
}
