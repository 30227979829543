import React from 'react';

export default class PhoneBtn extends React.Component {
	render() {
		return (
			<div className='fixed-btn' id='call'>
				<div className='circle-max'></div>
				<div className='circle-min'></div>
				<a href='tel:#' className='circle-center'>
					<div className='circle-img'></div>
				</a>
				<div className='fixed-btn-tip'>
					<a href='tel:#'>+38 (097) 555 44 11</a>
				</div>
			</div>
		);
	}
}
