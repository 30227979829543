import React from 'react';
import Lines from '../../components/Lines/Lines';

import present from './bg-sec5.png';
import hot from './hot.png';

class BuyForgive extends React.Component {
	render() {
		return (
			<section id='buy-forgive' className='buy-forgive'>
				<div className='container'>
					<div className='wrap df fww'>
						<div className='left'>
							<h2 className='lng-buy_forgive_h2'>Купуй і даруй!</h2>
							<div className='wrapper'>
								<p className='lng-buy_forgive_p1'>
									Прийшов сам - приведи друга! Найпростіший спосіб - подаруй
									йому заняття чи абонемент. І тобі веселіше буде катати з
									компанією, і питання, що ж незвичайного подарувати вирішиш!
								</p>
								<p className='lng-buy_forgive_p2'>
									Це ж стосується і дівчат: хочеш всі вихідні кататись на
									катері? Даруй коханому абонемент! Win-Win!
								</p>
							</div>
						</div>
						<div className='right'>
							<div className='wrap'>
								<img src={present} alt='present' />
							</div>
						</div>
					</div>
					<img className='hot' src={hot} alt='hot' />
				</div>
				<Lines />
			</section>
		);
	}
}

export default BuyForgive;
